import { Product, RecommendationCropwiseResponse } from 'base/types/RecommendationCropwise';
import { findCentroidOfAllFieldsInRec } from './getCentroidFields';
import { ProductSeed } from 'base/types/Products';

const maturityGroupMapping: {
  [key: string]: 'Early' | 'Medium' | 'Late';
} = {
  Early: 'Early',
  EARLY: 'Early',
  РАНЕН: 'Early',
  СРЕДНОРАНЕН: 'Early',
  MIDEARLY: 'Early',
  Timpuriu: 'Early',
  'Mid-early': 'Early',
  'Semi-timpuriu': 'Early',
  Mid: 'Medium',
  Late: 'Late',
  LATE: 'Late',
  MIDLATE: 'Late',
  СРЕДНОКЪСЕН: 'Late',
  'Mid-late': 'Late',
  'Semi-tardiv': 'Late',
};

export function mapMaturityGroup(maturityGroup: string | undefined): string {
  if (maturityGroup === undefined) {
    return 'Unknown';
  }
  const group = maturityGroup.trim();
  return maturityGroupMapping[group];
}

const getProductMaturityData = (
  productCatalog: ProductSeed[],
  p_name: string,
  cropType: string
) => {
  if (cropType.includes('Corn')) {
    const fao = productCatalog.find((product) => product.productName === p_name)?.fao;
    return fao ? `FAO${fao}` : undefined;
  } else {
    const maturityGroup = productCatalog.find(
      (product) => product.productName === p_name
    )?.maturityGroup;
    return mapMaturityGroup(maturityGroup);
  }
};

const getProductData = (productCatalog: ProductSeed[], products: Product[], cropType: string) => {
  return products.map((product: Product) => {
    return {
      productName: product.product_name,
      relativeMaturity: getProductMaturityData(productCatalog, product.product_name, cropType),
    };
  });
};

export const createGrowthStageReqData = (
  recommendationCropwise: RecommendationCropwiseResponse,
  plantingDate: string,
  productCatalog: ProductSeed[]
) => {
  const { recommendations } = recommendationCropwise;
  const {
    crop_type: cropType,
    multi_field: {
      geometry: { properties },
    },
    products,
  } = recommendations[0];
  let fieldCenter = [];
  fieldCenter = findCentroidOfAllFieldsInRec(properties[0].fields);
  const data = {
    crop: cropType.includes('Corn') ? 'CORN' : cropType.toUpperCase(),
    original_crop: cropType.toUpperCase(),
    products: getProductData(productCatalog, products, cropType),
    location: {
      geometry: fieldCenter?.geometry,
      type: 'Feature',
    },
    plantingDate: plantingDate,
  };
  return data;
};

// BBCH value is crop specific mapping reference below ticket
// https://digitial-product-engineering.atlassian.net/browse/SEED-20648
export const bbchMapping = [
  {
    crop: 'sunflower',
    stage: [
      { name: 'V2', bbchValue: 12 },
      { name: 'V6', bbchValue: 16 },
      { name: 'R5-1', bbchValue: 60 },
      { name: 'R9', bbchValue: 87 },
    ],
  },
  {
    crop: 'corn',
    stage: [
      { name: 'R1', bbchValue: 61 },
      { name: 'R6', bbchValue: 89 },
    ],
  },
  {
    crop: 'silage corn',
    stage: [
      { name: 'R1', bbchValue: 61 },
      { name: 'R3', bbchValue: 73 },
      { name: 'R6', bbchValue: 89 },
    ],
  },
];
