import styled from 'styled-components';
import { colors, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import IconChevronRight from 'components/Icons/IconChevronRight';
import IconSort from 'components/Icons/IconSort';
import breakpoints from 'base/constants/breakpoints';

interface IIconProps {
  disabled?: boolean;
  isOpened?: boolean;
}

const IconChevronRightStyled = styled(IconChevronRight) <IIconProps>`
  color: ${colors.neutral60};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transform: ${({ isOpened }) => (isOpened ? 'rotate(-90deg)' : 'rotate(90deg)')};
`;

const DivFilterStyled = styled(Typography.Text)`
  cursor: pointer;
`;

const IconSortStyled = styled(IconSort) <IIconProps>`
  cursor: pointer;
`;

const ColumnTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: ${colors.neutral100};
  margin: 0;
  display: inline-block;
  margin-right: 9.5px;
`;

const TableStyled = styled.table`
  border: 1px solid ${colors.neutral30};
  margin-top: 56px;
  td {
    padding-right: 5px;
  }
`;

const FieldCeilContainer = styled.div`
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
`;

const FieldCeilPreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 28px;
`;

const FieldCeilTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TrStyled = styled.tr`
  height: 52px;
  background-color: ${colors.neutral10};
  border-top: 1px solid ${colors.neutral50};
`;

interface ITdMainRowProps {
  hasPaddingRight?: boolean;
  centerText?: boolean;
  hasMarginLeft?: boolean;
  skuColumn?: boolean;
}

const TrTitleRow = styled.tr`
  height: 52px;
  background-color: ${colors.neutral20};
  padding-top: 16px;
  padding-bottom: 16px;
`;

const TdMainRow = styled.td<ITdMainRowProps>`
  padding-right: ${({ hasPaddingRight }) => (hasPaddingRight ? '8px' : undefined)};
  text-align: ${({ centerText }) => (centerText ? 'center' : undefined)};
`;

const TdColumnTitle = styled.td<ITdMainRowProps>`
  text-align: ${({ centerText }) => (centerText ? 'center' : undefined)};
  padding-left: ${({ hasMarginLeft }) => (hasMarginLeft ? '16px' : undefined)};
  @media screen and (min-width: 820px) and (max-width: 1180px) {
    ${({ skuColumn }) => skuColumn && `
      width: 150px;
      text-align: left;
    `}
  }
  @media screen and (max-width: 819px), (min-width: 1181px) {
    ${({ skuColumn }) => skuColumn && `
      width: 400px;
      text-align: left;
    `}
  }
`;

interface ITrExpandableRowProps {
  displayBorder?: boolean;
}

const TrExpandableRow = styled.tr<ITrExpandableRowProps>`
  height: 52px;
  border-bottom: ${({ displayBorder }) => (displayBorder ? `1px solid ${colors.neutral10}` : undefined)};
`;

const FieldNameText = styled(Typography.Text)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color ${colors.neutral100};
  margin: 0;
  @media screen and ${breakpoints.maxWidth.sm} {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.12px;
    color: #14151C;
  }
`;

const FieldAreaText = styled(Typography.Text)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color ${colors.neutral60};
  margin: 0;
  @media screen and ${breakpoints.maxWidth.sm} {
    letter-spacing: -0.105px;
    color: #696F88;
  }
`;

const RankText = styled(Typography.Text)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color ${colors.neutral100};
  margin: 0;
`;

const VarietyText = styled(Typography.Text)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color ${colors.neutral100};
  margin: 0;
`;
const SkuText = styled(Typography.Text)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color ${colors.neutral100};
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const CommonText = styled(Typography.Text)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color ${colors.neutral100};
  margin: 0;
`;

export {
  IconChevronRightStyled,
  ColumnTitle,
  TableStyled,
  FieldCeilContainer,
  FieldCeilPreviewContainer,
  TrStyled,
  FieldNameText,
  FieldAreaText,
  RankText,
  VarietyText,
  CommonText,
  TrExpandableRow,
  FieldCeilTextContainer,
  TdMainRow,
  DivFilterStyled,
  IconSortStyled,
  TdColumnTitle,
  TrTitleRow,
  SkuText,
};
