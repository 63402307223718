import breakpoints from 'base/constants/breakpoints';
import styled from 'styled-components';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

export const SectionHeading = styled.div`
  margin: 24px 0;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  @media screen and ${breakpoints.maxWidth.sm} {
    margin-top: 0;
    font-size: 20px;
    line-height: 28px;
  }
`;

export const SubHeadingSection = styled.div`
  margin: 20px 0;
  & h3 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 8px;
    @media screen and ${breakpoints.maxWidth.sm} {
      font-size: 16px;
      line-height: 24px;
    }
  }
  & p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    @media screen and ${breakpoints.maxWidth.sm} {
      color: ${colors.neutral70};
    }
  }
`;
export const TimelineContainer = styled.div`
  position: relative;
  width: 100%;
  height: 800px;
  overflow-y: hidden;
  overflow-x: hidden;
  height: auto;
  padding-bottom: 64px;
  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 8px 16px;
  }
`;

export const DataInputSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
  @media screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;
export const PlantingDateSection = styled.div`
  width: 25%;
  font-size: 14px;
  @media screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
  }
  & span {
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: ${colors.neutral60};
  }
`;
export const MonthLabelContainer = styled.div`
  position: relative;
  height: 33px;
`;
export const MonthLabel = styled.div<{
  left: number;
  width: number;
  isDefault: boolean;
  isLastMonth?: boolean;
}>`
  position: absolute;
  left: ${(props) => (props.left === 0 && props.isDefault ? '0' : `calc(15% +  ${props.left}%)`)};
  width: ${(props) => (props.isLastMonth ? '-webkit-fill-available' : `${props.width}%`)};
  width: ${(props) => (props.isLastMonth ? '-moz-available' : `${props.width}%`)};
  height: inherit;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => (props.isDefault ? '' : colors.neutral60)};
  border: ${(props) => (props.isDefault ? 'none' : `1px solid ${colors.neutral30}`)};
  align-content: center;

  & img {
    margin-right: 8px;
  }


  @media screen and ${breakpoints.maxWidth.sm} {
    left: ${(props) => (props.left === 0 && props.isDefault ? '0' : `calc(9% + ${props.left}%)`)};
    width: ${(props) => `${props.width}%`};
    border-radius: ${(props) => props.isLastMonth && '0px 3px 0px 0px'};
    border-left: ${(props) => !props.isDefault && `1px solid ${colors.neutral30}`};
    border-right: ${(props) => !props.isDefault && '0px'};
    border-top: ${(props) => !props.isDefault && '0px'};
    border-bottom: ${(props) => !props.isDefault && `1px solid ${colors.neutral30}`};

    &:first-child {
      border-bottom: 1px solid ${colors.neutral30} !important;
      border-right: 1px solid ${colors.neutral30} !important;
      width: 9%;
      padding: 6px 9px !important;
    }

`;

export const StagesContainer = styled.div<{ width?: number }>`
  height: 34px;
  width: 100%;
  & div {
    display: inline-block;
    text-align: center;
    padding: 7px 0;
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    height: 28px;
  }
`;

export const GrowthStageTextContainer = styled.div`
  height: 36px;
  @media screen and ${breakpoints.maxWidth.sm} {
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & img {
      padding: 8px;
    }
  }
`;

export const SecondaryGrowthStageTextContainer = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  float: right;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 4px 6px;

  & span {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.neutral60};
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    & span {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      text-align: center;
    }
  }
`;

export const PlantingIcon = styled.div<{ width?: string }>`
  background-color: ${colors.neutral10};
  width: ${(props) => props.width};
  @media screen and ${breakpoints.maxWidth.sm} {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${colors.neutral10};
    border-radius: 2px;
    padding: 0px 4px !important;
  }
`;

export const StagesText = styled.div`
  width: 15%;
  text-align: left !important;
  padding: 6px !important;
  & span {
    margin-left: 8px;
  }
`;

export const GrowthStageBar = styled.div<{
  isEmptyMonthRequired?: boolean;
  width: string;
}>`
  width: ${(props) => props.width} !important;
  & span {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 7px 0;
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    width: ${(props) => (props.isEmptyMonthRequired ? '84%' : props.width)} !important;
    float: right;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    & span {
      font-weight: 400;
      padding: 3px;
      font-size: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      text-align: center;
    }
  }
`;

export const GraphSection = styled.div`
  background-color: #f8fafe;
  @media screen and ${breakpoints.maxWidth.sm} {
    border: 1px solid #c2c7d0;
    border-radius: 4px;
  }
`;

export const ProductLabel = styled.div`
  position: absolute;
  left: 0;
  font-weight: bold;
  z-index: 1;
  border-right: 1.5px dashed #696f888c;
  width: 15%;
  height: inherit;
  align-content: center;
  padding: 16px;
  background-color: ${colors.neutral10};
  word-wrap: break-word;
  overflow-wrap: break-word;

  @media screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    display: flex;
    height: 28px;
    padding: 0px 8px;
    align-items: center;
    gap: 4px;
    border-right: none;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.075px;
    border-top: 1px solid #c2c7d0;
  }
`;

export const CardWrapper = styled.div<{
  top: number;
  left: number;
  width: number;
  deviationMargin: number;
  isLastDate: boolean;
  isOverLappingForProduct: boolean;
}>`
  position: absolute;
  left: calc(14.9% + ${(props) => props.left}%) !important;
  width: ${(props) => props.width}%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: inherit;
  & img {
    z-index: 9;
    height: inherit;
  }
  &::after {
    content: '';
    left: calc(2.3px + ${(props) => props.deviationMargin}%) !important;
    position: absolute;
    height: inherit;
    z-index: 9;
    border-left: ${(props) => (props.deviationMargin === 0 ? 'none' : '2px dashed #c2c7d08c')};
  }
  &::before {
    content: '';
    left: calc(2.3px - ${(props) => props.deviationMargin}%) !important;
    position: absolute;
    height: inherit;
    z-index: 9;
    border-left: ${(props) => (props.deviationMargin === 0 ? 'none' : '2px dashed #c2c7d08c')};
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    left: calc(8.2% + ${(props) => props.left}%) !important;
    top: 28px;
    height: ${(props) => (props.isOverLappingForProduct ? '127px' : '76px')};

    &::after {
      border-left: ${(props) =>
    (props.deviationMargin === 0 || props.isLastDate ? 'none' : '2px dashed #c2c7d08c')};
    }

    & img {
      height: inherit;
    }
  }
`;

// can remove below styles for VerticalLine and Dot
export const VerticalLine = styled.div<{ isVegetative: boolean }>`
  position: absolute;
  left: 0px;
  top: -20px;
  bottom: -20px;
  width: 1px;
  background-color: ${(props) => (props.isVegetative ? '#4caf50' : '#E99921')};
`;

export const Dot = styled.div<{ isVegetative: boolean }>`
  position: absolute;
  top: -20px;
  left: -3px;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => (props.isVegetative ? '#4caf50' : '#E99921')};
  z-index: 1;
`;
const getBorderStyle = (
  isHeatStressOverlapping: boolean,
  isVegetative: boolean,
  stressType: string
): string => {
  switch (true) {
    case isHeatStressOverlapping && stressType === 'Cold':
      return '1px solid #0092E4';
    case isHeatStressOverlapping && stressType === 'Heat':
      return '1px solid #EB4B4B';
    case isVegetative:
      return '1px solid #4caf50';
    case !isVegetative:
      return '1px solid #E99921';
    default:
      return '1px solid #4caf50';
  }
};

export const CardContainer = styled.div<{
  isVegetative: boolean;
  isHeatStressOverlapping: boolean;
  stressType: string;
  isOverLap: boolean;
  isOverLappingForProduct: boolean;
}>`
  position: relative;
  top: ${(props) => {
    if (!props.isOverLappingForProduct) {
      return '0';
    }
    return props.isOverLap ? '34px' : '-32px';
  }};
  width: 54px;
  border: ${(props) =>
    getBorderStyle(props.isHeatStressOverlapping, props.isVegetative, props.stressType)};
  border-radius: 2px;
  text-align: center;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  margin-left: ${(props) => {
    if (props.isOverLappingForProduct) {
      return props.isVegetative ? '-4px' : '-58px';
    }
    return props.isVegetative ? '-3px' : '-57px';
  }};
  z-index: 10;
  background-color: ${colors.neutral00};
  @media screen and ${breakpoints.maxWidth.sm} {
    top: ${(props) => {
    if (!props.isOverLappingForProduct) {
      return '0';
    }
    return props.isOverLap ? '28px' : '-28px';
  }};
    width: 94%;
    margin-left: ${(props) => !props.isVegetative && '-101%'};
  }
`;

export const StageInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 2vw;
`;

export const StageName = styled.div<{
  subStage: string;
  isHeatStressOverlapping: boolean;
  stressType: string;
}>`
  padding: 3px;
  line-height: 12px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  font-size: 10px;
  background-color: ${(props) => {
    switch (true) {
      case props.isHeatStressOverlapping && props.stressType === 'Heat':
        return '#FFF1F1';
      case props.isHeatStressOverlapping && props.stressType === 'Cold':
        return '#EAF6FF';
      case props.subStage.toLowerCase() === 'flowering':
        return '#FFF3DD';
      case props.subStage.toLowerCase() === 'silking':
        return '#FFF3DD';
      case props.subStage.toLowerCase() === 'milkstage':
        return '#FFF3DD';
      case props.subStage.toLowerCase() === 'vegetative':
        return '#DFFBE8';
      case props.subStage.toLowerCase() === 'maturity':
        return '#FFE4AE';
      default:
        return '#FFFFFF'; // Default color if none of the conditions are met
    }
  }};
  .info-bubble {
    cursor: pointer;
  }
  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 2px;
    font-size: 100%;
    .stage-info-icon {
      width: 27%;
      height: 70%;
    }
    .info-bubble {
      width: 27%;
      height: 70%;
    }
  }
  & span {
    margin: 0 1px;
  }
`;

export const DateContainer = styled.div`
  padding: 4px 4px;
  font-size: 9px;
  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 2px;
    font-size: 85%;
  }
`;

export const DateInfo = styled.div`
  line-height: 16px;

  @media screen and ${breakpoints.maxWidth.sm} {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: -0.075px;
  }
`;

export const DeviationInfo = styled.div`
  color: #555;
  line-height: 16px;
  @media screen and ${breakpoints.maxWidth.sm} {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: -0.06px;
  }
`;

export const PhenologyIcon = styled.div`
  width: 9%;
  border-right: 1px solid ${colors.neutral30};
  border-bottom: 1px solid ${colors.neutral30};
  padding: 3px 6px !important;
`;

export const FooterSection = styled.div`
  padding: 16px 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  @media screen and ${breakpoints.maxWidth.sm} {
    justify-content: center;
    padding: 8px 16px;
  }
`;

export const AbioticStressColorCodeSection = styled.div`
  display: flex;
  gap: 0px 16px;
  flex-wrap: wrap;
  justify-content: center;

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  & h3 {
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.09px;
    color: #696f88;
    padding-right: 3px;
    display: inline;
    margin-right: 4px;

    @media screen and ${breakpoints.maxWidth.sm} {
      padding: ;
    }
  }

  & span {
    padding: 2px 4px;
    margin-right: 2px;
    font-size: 10px;
  }

  #lowHeatStress {
    background: rgba(248, 124, 124, 0.25);
  }
  #medHeatStress {
    background: rgba(248, 124, 124, 0.5);
  }
  #highHeatStress {
    background: rgba(248, 124, 124, 0.75);
  }

  #lowColdStress {
    background: rgba(130, 207, 255, 0.25);
  }
  #medColdStress {
    background: rgba(130, 207, 255, 0.5);
  }
  #highColdStress {
    background: rgba(130, 207, 255, 0.75);
  }
`;

export const GrowthStageColorCodeSection = styled.div`
  & div {
    display: inline;
    margin-left: 3px;
    padding: 2px;
    border-radius: 2px;
  }

  & span {
    padding-left: 2px;
  }

  #plantingColor {
    background: #f3f4f6;
  }
  #leaveEmergenceColor {
    background: #dffbe8;
  }
  #floweringColor {
    background: #fff3dd;
  }
  #maturityColor {
    background: #ffe4ae;
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-bottom: 8px;

    & div {
      display: inline;
      margin-left: 3px;
      padding: 1px 2px;
      border-radius: 2px;
    }
    & span {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: -0.075px;
    }
  }
`;

export const AbioticGraphContainer = styled.div<{
  graphHeight: number;
}>`
  height: ${(props) => props.graphHeight}px;
  position: absolute;
  top: 0px;
  width: 100%;
  overflow: hidden;
`;
export const GrowthStageTimeline = styled.div<{
  isOverLap: boolean;
}>`
  height: ${(props) => (props.isOverLap ? '155px' : '104px')};
  background-color: #fff;
  position: relative;
  margin-top: 3px;
`;
