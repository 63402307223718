import React, { useMemo } from 'react';
import {
  CardContainer,
  CardWrapper,
  DateContainer,
  DateInfo,
  DeviationInfo,
  StageInfo,
  StageName,
} from './GrowthStage.styles';
import Vegetative from 'assets/icons/vegetative_icon.svg';
import Reproductive from 'assets/icons/reproductive_icon.svg';
import ColdStress from 'assets/icons/Cold_Stress_Line.svg';
import HeatStress from 'assets/icons/Heat_Stress_Line.svg';
import { useTranslation } from 'react-i18next';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import InfoBubble from 'components/InfoBubble';
import {
  isMobile as isMobileByUserAgent,
  isTablet as isTabletByUserAgent,
} from 'react-device-detect';
import { bbchMapping } from 'utils/growthStage';

const IconLeaveEmergence = React.lazy(() => import('components/Icons/IconLeaveEmergence'));
const IconWetLeaf = React.lazy(() => import('components/Icons/IconWetLeaf'));
const IconCropCycle = React.lazy(() => import('components/Icons/IconCropCycle'));
const IconMilkStage = React.lazy(() => import('components/Icons/IconMilkStage'));

interface GrowthStageCardProps {
  stageName: string;
  date: string; // Expect date in format "YYYY-MM-DD"
  top: number;
  left: number;
  type: string;
  deviation: number;
  cropName: string;
  monthWidth: number;
  isLastDate: boolean;
  hasOverlappedWithStress: { isOverlapping: boolean; stressValue?: number; type?: string };
  isOverlapping: boolean;
  isOverLappingForProduct: boolean;
}

const GrowthStageCard: React.FC<GrowthStageCardProps> = ({
  stageName,
  date,
  top,
  left,
  type,
  deviation,
  cropName,
  monthWidth,
  isLastDate,
  hasOverlappedWithStress,
  isOverlapping,
  isOverLappingForProduct,
}) => {
  const { t } = useTranslation();
  const parsedDate = new Date(date);

  // Format date for display
  const formattedDate = parsedDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const iconColor = (isOverlapping: boolean, stressType: string, defaultColor: string) => {
    switch (true) {
      case isOverlapping && stressType === 'Heat':
        return colors.red50;
      case isOverlapping && stressType === 'Cold':
        return colors.blue50;
      default:
        return defaultColor; // Default color if none of the conditions are met
    }
  };

  const stageDetails = useMemo(() => {
    const baseColor = '#E99921';

    const stagesConfig = {
      sunflower: {
        'R5-1': { icon: IconWetLeaf, subStage: 'flowering', color: baseColor },
        R9: { icon: IconCropCycle, subStage: 'maturity', color: baseColor },
      },
      corn: {
        R1: { icon: IconWetLeaf, subStage: 'silking', color: baseColor },
        R6: { icon: IconCropCycle, subStage: 'maturity', color: baseColor },
      },
      'silage corn': {
        R1: { icon: IconWetLeaf, subStage: 'silking', color: baseColor },
        R3: { icon: IconMilkStage, subStage: 'milkStage', color: baseColor },
        R6: { icon: IconCropCycle, subStage: 'maturity', color: baseColor },
      },
    };

    if (stageName.startsWith('V')) {
      return {
        icon: (
          <IconLeaveEmergence
            color={iconColor(
              hasOverlappedWithStress.isOverlapping,
              hasOverlappedWithStress.type ?? '',
              colors.green50
            )}
            className="stage-info-icon"
          />
        ),
        subStage: 'vegetative',
      };
    }

    const cropConfig = stagesConfig[cropName as keyof typeof stagesConfig];
    const stageConfig: {
      icon: React.LazyExoticComponent<
        ({ height, width, color, ...props }: React.SVGProps<SVGSVGElement>) => JSX.Element
      >;
      subStage: string;
      color: string;
    } = cropConfig?.[stageName as keyof typeof cropConfig];

    if (stageConfig) {
      return {
        icon: (
          <stageConfig.icon
            color={iconColor(
              hasOverlappedWithStress.isOverlapping,
              hasOverlappedWithStress.type ?? '',
              stageConfig.color
            )}
            className="stage-info-icon"
          />
        ),
        subStage: stageConfig.subStage,
      };
    }
  }, [stageName, cropName, hasOverlappedWithStress]);

  function formatDate(dateString: string) {
    const date = dateString?.split(',')[0]?.split(' ');
    return `${t(date[0])} ${date[1]}`;
  }
  const getDescription = (stressValue: number, type: string) => {
    const value = Number(stressValue.toFixed(2));
    let range = '';
    switch (true) {
      case value <= 3.33 && value > 0:
        range = 'Low';
        break;
      case value <= 6.66:
        range = 'Medium';
        break;
      case value >= 6.67:
        range = 'High';
        break;
    }
    return <span>{`${t('Expected')} ${t(range)} ${t(type)} ${t('Stress')}`}</span>;
  };

  const renderGrowthStageLine = (
    stageType: string,
    isOverLappingForProduct: boolean,
    stressType: string
  ) => {
    switch (true) {
      case isOverLappingForProduct && stressType === 'Cold':
        return <img src={ColdStress} alt="No img found" />;
      case isOverLappingForProduct && stressType === 'Heat':
        return <img src={HeatStress} alt="No img found" />;
      case stageType === 'vegetative':
        return <img src={Vegetative} alt="No img found" />;
      case stageType === 'reproductive':
        return <img src={Reproductive} alt="No img found" />;
      default:
        return <img src={Vegetative} alt="No img found" />;
    }
  };

  const getStageBbch = (): string | number => {
    return (
      bbchMapping.find((_c) => _c.crop === cropName)?.stage.find((_v) => _v.name === stageName)
        ?.bbchValue ?? ''
    );
  };

  return (
    <CardWrapper
      top={top}
      left={left}
      width={monthWidth}
      deviationMargin={(deviation / 30) * 100}
      isLastDate={isLastDate}
      isOverLappingForProduct={isOverLappingForProduct}
    >
      {renderGrowthStageLine(
        type,
        hasOverlappedWithStress.isOverlapping,
        hasOverlappedWithStress.type ?? ''
      )}
      <CardContainer
        isHeatStressOverlapping={hasOverlappedWithStress.isOverlapping}
        stressType={hasOverlappedWithStress.type ?? ''}
        isVegetative={type === 'vegetative'}
        isOverLap={isOverlapping}
        isOverLappingForProduct={isOverLappingForProduct}
      >
        <StageInfo>
          <StageName
            isHeatStressOverlapping={hasOverlappedWithStress.isOverlapping}
            stressType={hasOverlappedWithStress.type ?? ''}
            subStage={stageDetails?.subStage ?? ''}
          >
            {stageDetails?.icon ?? null}
            <span>{getStageBbch()}</span>
            {hasOverlappedWithStress.isOverlapping && (
              <InfoBubble
                title={getDescription(
                  hasOverlappedWithStress.stressValue ?? 0,
                  hasOverlappedWithStress.type ?? ''
                )}
                trigger={isMobileByUserAgent || isTabletByUserAgent ? ['click'] : ['hover']}
                width="10"
                height="10"
                iconColor={hasOverlappedWithStress.type === 'Heat' ? '#EB4B4B' : '#0092E4'}
                viewBox="-1 1 18 11"
                className="info-bubble"
              />
            )}
          </StageName>
          <DateContainer>
            <DateInfo> {formatDate(formattedDate)}</DateInfo>

            <DeviationInfo>
              ± {deviation} {deviation > 1 ? t('days') : t('day')}
            </DeviationInfo>
          </DateContainer>
        </StageInfo>
      </CardContainer>
    </CardWrapper>
  );
};

export default GrowthStageCard;
